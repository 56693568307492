@import '../../variables.scss';

#our-game-item-wrapper {
  &.max-width {
    max-width: 220px;
  }

  .game-image {
    height: 220px;
    width: 220px;
    border-radius: 3rem;
    background-size: cover;
    background-position: center;
  }

  .app-store-image {
    display: block;
    width: 150px;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    .game-image {
      height: 150px;
      width: 100%;
    }
  }
}
