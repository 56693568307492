@import './variables.scss';

body {
  background-color: #2a2829;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url('./fonts/proximanova/proxima-nova.otf') format('opentype');
  font-weight: normal;
}

* {
  font-family: ProximaNova;
}

h1 {
  font-size: 88px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 34px;
}

.content-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-white {
  color: white;
}

.text-small {
  font-size: 16px;
}

.text-paragraph {
  font-size: 24px;
  color: white;
}

.title-container {
  width: auto;
}

.p-6 {
  padding: 5rem;
}

.ps-6 {
  padding-left: 5rem;
}

.pe-6 {
  padding-right: 5rem;
}

.pt-6 {
  padding-top: 5rem;
}

.pb-6 {
  padding-bottom: 5rem;
}

@media screen and (max-width: $desktop-breakpoint) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  .text-small {
    font-size: 14px;
  }

  .text-paragraph {
    font-size: 18px;
    color: white;
  }

  .title-container {
    width: 100%;
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 28px;
  }

  .text-small {
    font-size: 15px;
  }

  .text-paragraph {
    font-size: 20px;
    color: white;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 66px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 30px;
  }

  .text-small {
    font-size: 16px;
  }

  .text-paragraph {
    font-size: 22px;
    color: white;
  }
}

@media screen and (min-width: 2000px) {
  h1 {
    font-size: 88px;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 34px;
  }

  .text-small {
    font-size: 16px;
  }

  .text-paragraph {
    font-size: 24px;
    color: white;
  }
}
