@import '../../../variables.scss';

#team-wrapper {
  position: relative;
  overflow: hidden;

  .background {
    background: linear-gradient(rgba(30, 30, 30, 0.9), rgba(30, 30, 30, 0.9)),
      url('../../../../public/resources/backgrounds/about_us_bg.jpg');
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    z-index: -1;
  }

  .team-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-size: cover;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .team-name {
    color: white;
    text-align: center;
    font-size: 34px;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    .background {
      transform: scale(1.5);
    }

    .team-image {
      width: 150px;
      height: 150px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .team-name {
      font-size: 18px;
      margin-top: 0.5rem;
    }
  }
}
