@import '../../../variables.scss';

#our-games-wrapper {
  position: relative;
  overflow: hidden;

  .background {
    background: linear-gradient(rgba(40, 40, 40, 0.9), rgba(40, 40, 40, 0.9)),
      url('../../../../public/resources/backgrounds/our_games_bg.jpg');
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-position-y: 100px;
    scale: 1.5;
    z-index: -1;
  }

  .games-list {
    max-width: 1300px;
  }

  .game-item-container {
    width: auto;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    .game-item-container {
      width: 100%;
    }
  }
}
