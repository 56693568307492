@import '../../variables.scss';

#footer-wrapper {
  background-color: #2a2829;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .content-container {
    max-width: 1200px;
  }

  .footer-item {
    text-align: center;
    color: white;
  }

  .logo {
    height: 150px;
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    padding-top: 5rem;
    padding-top: 5rem;
  }
}
