@import '../../../variables.scss';

#about-us-wrapper {
  background-color: #13101e;

  .about-us-section {
    display: flex;
    max-width: 1400px;
    margin-bottom: 4rem;
  }

  .about-us-section-text {
    padding-bottom: 2rem;

    &.text-left {
      margin-right: 5rem;
    }

    &.text-right {
      margin-left: 5rem;
    }
  }

  .about-us-section-image {
    min-width: 400px;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    background-size: cover;
    background-position: center;
  }

  .about-us-section-big {
    max-width: 100%;
    width: 1400px;
    height: 800px;
    position: relative;
  }

  .about-us-section-image-big {
    height: 450px;
    max-width: 800px;
    border-radius: 3rem;
    background-size: cover;
    background-position: center;
  }

  .np-quote-text {
    position: absolute;
    padding: 3rem;
    background-color: white;
    font-size: 32px;
    color: black;
    top: 300px;
    right: 0px;

    .np-quote {
      font-size: 18px;
      color: #757575;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  @media screen and (max-width: $desktop-breakpoint) {
    .about-us-section {
      flex-direction: column;
    }

    .about-us-section-text {
      padding-bottom: 2rem;

      &.text-left {
        margin-right: 0rem;
      }

      &.text-right {
        margin-left: 0rem;
      }
    }

    .about-us-section-image {
      min-width: auto;
      height: 200px;
      background-size: cover;
      background-position: center;
    }

    .about-us-section-big {
      height: auto;
    }

    .about-us-section-image-big {
      height: 200px;
      width: 100%;
      max-width: none;
      margin-bottom: 1rem;
    }

    .np-quote-text {
      width: 100%;
      padding: 2rem;
      position: initial;
      margin-bottom: 2rem;
      font-size: 18px;

      .np-quote {
        font-size: 14px;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  @media screen and (min-width: $desktop-breakpoint) {
    .np-quote-text {
      font-size: 24px;

      .np-quote {
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .np-quote-text {
      font-size: 26px;

      .np-quote {
        font-size: 15px;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .np-quote-text {
      font-size: 30px;

      .np-quote {
        font-size: 16px;
      }
    }
  }
}
