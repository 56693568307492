@import '../../variables.scss';

#top-menu-wrapper {
  position: fixed;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 8vh;
  min-height: 70px;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0.2);
  backdrop-filter: blur(7px) !important;

  &.full-screen {
    height: 100vh;
    background-color: #2a2829;
    transition: all 0.25s ease-out;
  }

  &:not(.full-screen) {
    transition: all 0.25s ease-out;
  }

  .nav-logo {
    height: 100%;
    max-height: 4rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .nav-link {
    font-size: 18px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .activeIndicator {
      width: 0px;
      height: 0.1rem;
      border-radius: 10rem;
      background-color: transparent;
      transition: width 0.5s ease-in-out;
      background-color: white;
    }

    &:not(.current) {
      .activeIndicator {
        width: 0px;
        transition: width 0.5s ease-in-out;
      }
    }

    &.current {
      .activeIndicator {
        width: 100%;
        transition: width 0.5s ease-in-out;
      }
    }
  }

  .collapse-icon {
    color: white;
    font-size: 2rem;
    transition: all 1s linear;
  }

  .burger-link {
    margin-left: 1rem;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}
