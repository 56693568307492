@import '../../../variables.scss';

#intro-wrapper {
  position: relative;
  overflow: hidden;

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('../../../../public/resources/backgrounds/intro_bg.png');
    background-size: cover;
    background-position: center center;
    z-index: -1;
  }

  .intro-text {
    padding-top: 12rem;
    padding-bottom: 12rem;
    width: 800px;
    z-index: 1;
  }
  .intro-logo {
    max-height: 25rem;
    z-index: 1;
  }

  @media screen and (max-width: $desktop-breakpoint) {
    .intro-logo {
      max-height: 15rem;
    }
  }

  @media screen and (min-width: $desktop-breakpoint) {
    .intro-logo {
      max-height: 20rem;
    }
  }

  @media screen and (min-width: 2000px) {
    .intro-logo {
      max-height: 25rem;
    }
  }
}
